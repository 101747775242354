import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import GoogleIcon from 'views/common/components/UI/Icons/GoogleIcon';
import FacebookIcon from 'views/common/components/UI/Icons/FacebookIcon';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import axios, { API } from 'lib/config/axios';

import { setAppData } from 'redux/features/globalData-slice';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useTranslation } from 'next-i18next';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { FE } from 'lib/navigation/apps';
import clsx from 'clsx';
import useSSONavigate from 'views/common/hooks/useSSONavigate';
import capitalize from 'lib/utils/capitalize';

const PREFIX = 'SocialLogin';

const classes = {
  otherLoginBtn: `${PREFIX}-otherLoginBtn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.otherLoginBtn}`]: {
    letterSpacing: theme.spacing(0.125),
    textTransform: 'uppercase',
  },
}));

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const WindowOpener = dynamic(() => import('lib/utils/WindowOpener'), {
  ssr: false,
});

const IDB_PROVIDERS = {
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
};

const SocialLogin = (props) => {
  const { className } = props;
  const { t } = useTranslation('fe_er_auth');
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const appState = useSelector((state) => state.globalData.appState);
  const { moveToERSSOPage } = useSSONavigate();

  const [providerData, SetProviderData] = useState(null);

  const getHostedUIThirdpartyUrl = (provider, returnUrl) => `${envVars.COGNITO_USER_POOL_DOMAIN}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${returnUrl}&response_type=CODE&client_id=${envVars.COGNITO_CLIENT_ID}`;

  const mapProviderToRedirectUri = useMemo(() => {
    if (typeof window === 'undefined') return {};

    return {
      google: `${window.location.origin}/auth/google`,
      facebook: `${window.location.origin}/auth/facebook`,
    };
  }, []);
  const getToken = () => axios
    .get(API.authenticityToken)
    .then(
      (res) => res.data.authenticity_token,
      // handleSignIn(res.data.authenticity_token);
    )
    .catch((error) => {
      handleErrorResponse(error);
    });
  const signInUser = (signParams) => axios.post(API.users.thirdParty, {
    identity_provider: capitalize(signParams.provider),
    redirect_uri: signParams.redirectUri,
    code: signParams.code,
    authenticity_token: signParams.authenticityToken,
  });
  const signInViaThirdParty = async () => {
    try {
      dispatch(setLoading(true));

      const authenticityToken = await getToken();
      const res = await signInUser({
        provider: providerData.provider,
        redirectUri: mapProviderToRedirectUri[providerData.provider],
        code: providerData.code,
        authenticity_token: authenticityToken,
      });
      dispatch(
        setAppData({
          ...appState,
          ...res.data,
        }),
      );
      let path = null;
      if (appNavigator?.query?.return_url) {
        path = { url: appNavigator.query.return_url, app: FE };
      } else if (appNavigator.query.membership_id) {
        path = PATHS.motorcycleClub.checkout({ ...appNavigator.query });
      } else {
        path = PATHS.home();
      }

      moveToERSSOPage(
        res.data.access_token,
        appNavigator.getUrl(undefined, path),
      );
    } catch (error) {
      try {
        switch (error.response.status) {
          case 401:
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response.data.message,
              }),
            );
            break;
          default:
            handleErrorResponse(error);
        }
      } catch (e) {
        handleErrorResponse(error);
      }
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const providerResponse = (err, res) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log(res, 'error');
    }

    if (res.code) {
      SetProviderData(res);
    } else {
      // eslint-disable-next-line no-alert
      alert('something wrong please try again ');
    }
  };
  useEffect(() => {
    providerData && signInViaThirdParty();
  }, [providerData]);

  return (
    <Root className={className}>
      <Box mb={2}>
        <WindowOpener
          url={getHostedUIThirdpartyUrl(
            IDB_PROVIDERS.GOOGLE,
            `${window.location.origin}/auth/google`,
          )}
          bridge={providerResponse}
          width={600}
          height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, 'SLGoogle')}
            fullWidth
            variant="outlined"
            color="secondary">
            <Box component="span" lineHeight="10px" mr={1}>
              <GoogleIcon />
            </Box>
            {t('fe_er_auth:login_with_google')}
          </Button>
        </WindowOpener>
      </Box>
      <Box mb={2}>
        <WindowOpener
          url={getHostedUIThirdpartyUrl(
            IDB_PROVIDERS.FACEBOOK,
            `${window.location.origin}/auth/facebook`,
          )}
          bridge={providerResponse}
          width={600}
          height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, 'SLFacebook')}
            fullWidth
            variant="outlined"
            color="secondary">
            <Box component="span" lineHeight="10px" mr={1}>
              <FacebookIcon />
            </Box>
            {t('fe_er_auth:login_with_facebook')}
          </Button>
        </WindowOpener>
      </Box>
    </Root>
  );
};

export default SocialLogin;
